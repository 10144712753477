import React, { Component } from 'react';
import '../Styles/ExitGame.css'
import Utility from '../Utility';

class ExitGame extends Component {
	constructor(props) {
		super(props)

		this.state = {
			btnPos: 0,
			activePos: 100
		}
	}
	componentDidMount() {
		this.props.setScreen("gameExit")
		Utility.addKeyHandler(this.keyHandler);
	}

	componentWillUnmount(){
		Utility.removeKeyHandler(this.keyHandler);
	}

	keyHandler = (key) => {

		switch (key.keyCode) {
			case Utility.input.left:
				if(this.state.btnPos === 0){
					this.setState({
						btnPos: 1,
						activePos:0
					});
				}
				break;

			case Utility.input.right:
				if(this.state.btnPos === 1){
					this.setState({
						btnPos: 0,
						activePos:100
					});	
				}
				break;

			case Utility.input.enter:
				this.enter();
				break;

			case Utility.input.backPC:
			case Utility.input.backLG:
			case Utility.input.backSam:
				this.back();
				break;
		}
	}

	enter = () => {
		switch (this.state.btnPos) {
			case 0: // NO
				this.back();
				break;
			case 1: //YES
				this.props.backTohome();
		}
	}

	back = () => {
		this.props.setShowExit();
	}

	render() {

		return (
			<div id="exit" className="exit">

				<div className="exit-container">
					<div className="exit-txt">
						<span>Exit game and return to homepage?</span>
					</div>
					<div className="buttons">
						<div className="btn-container">
						<div id="activeBox" style={{WebkitTransform: `translateX(${this.state.activePos}%)`}} />
							<div className={"btng" + (this.state.btnPos === 1 ? " active" : "")} > Yes </div>
							<div className={"btng" + (this.state.btnPos === 0 ? " active" : "")} > No </div>

						</div>
					</div>
				</div>

				<div className="appImage">
					<img src={this.props.appLogo} width="100%" />
				</div>

			</div>
		)
	}
}

export default ExitGame
