import React, { Component } from 'react'
import Utility from '../Utility';
import GameCard from './GameCard';
import '../Styles/Pages.css'

class SearchUI extends Component {
	constructor(props) {
		super(props)

		this.state = {
			inputValue: "",
			filterArray: [],
			searchItemActive: -1,
			mouseOver: false,
			position: 0,
			prevPostion: 0,
			currentRow: 0,
			rows: 0,
			sliderTimes: 0,
			showError: false
		}

		this.searchItemHeight = 0;
	}

	componentDidMount() {
		Utility.addKeyHandler(this.keyHandler);
		this.setRows();

	}

	componentWillUnmount() {
		Utility.removeKeyHandler(this.keyHandler)
	}

	componentDidUpdate(prevProps, prevState) {
		if (this.props.screen !== prevProps.screen) {
			if (this.props.screen == "search"){
				Utility.addKeyHandler(this.keyHandler);	
				this.setState({
					position: this.state.prevPostion
				});
			}
			else
			Utility.removeKeyHandler(this.keyHandler);
		}

		// if (this.state.searchItemActive !== prevState.searchItemActive) {
		// 	this.props.setActiveGame(this.state.filterArray[this.state.searchItemActive])
		// }

		if (this.state.position !== prevState.position) {
			switch (this.state.position) {
				case 0:
					document.getElementById("search").focus();
					break;
				case 3:
				case 1:
					document.getElementById("search").blur();
					break

			}
		};

	}

	setRows = () => {
		this.setState({
			rows: Math.ceil(this.state.filterArray.length / 5)
		})
	}

	keyHandler = (key) => {

		let code = key.keyCode;

		switch (key.keyCode) {
			case Utility.input.up:
				this.up();
				break;
			case Utility.input.down:
				this.down();
				break;
			case Utility.input.left:
				this.left();
				break;
			case Utility.input.right:
				this.right();
				break;
			case Utility.input.enter:
				this.enter();
			break
			case Utility.input.backLG:
			case Utility.input.backPC:
			case Utility.input.backSam:
				this.props.setScreen("menu");
				this.setState({
					prevPostion: this.state.position,
					position: 3

				})
				break;
		}
	}

	down = () => {
		if (this.state.position === 0 && this.state.filterArray.length > 0) { //search input
			this.setState({
				position: 1,
				prevPostion: 1,
				searchItemActive: 0
			})
		} else {
			if (this.state.currentRow + 1 < this.state.rows - 1) {
				this.setState({
					searchItemActive: this.state.searchItemActive + 5,
					currentRow: this.state.currentRow + 1,
					sliderTimes: this.state.sliderTimes + 1
				})
			} else {
				if (this.state.searchItemActive + 5 > this.state.filterArray.length - 1 && this.state.currentRow < this.state.rows - 1) {
					this.setState({
						searchItemActive: this.state.filterArray.length - 1,
						currentRow: this.state.currentRow + 1
					})
				} else if (this.state.currentRow < this.state.rows - 1) {
					this.setState({
						searchItemActive: this.state.searchItemActive + 5,
						currentRow: this.state.currentRow + 1
					})
				}

			}
		}
	}

	right = () => {
		const index = this.state.searchItemActive;

		if ((index + 1) % 5 !== 0) {
			if (index < this.state.filterArray.length - 1) {
				this.setState((state) => ({
					searchItemActive: state.searchItemActive + 1
				}))
			}
		}
	}

	left = () => {

		if (this.state.position === 0) {
			this.props.setScreen("menu");
			this.setState({
				position: 3,
				prevPostion: 0 
			})
		}else{
			const index = this.state.searchItemActive;
			if ((index) % 5 === 0) {
				this.props.setScreen("menu");
				this.setState({
					position: 3,
					prevPostion: 1
				})
			} else {
				if (index > 0) {
					this.setState((state) => ({
						searchItemActive: state.searchItemActive - 1
					}))
				}
			}
	
		}
		
	}

	up = () => {

		if (this.state.searchItemActive < 5) {
			this.setState({
				position: 0,
				prevPostion: 0
			})
		} else {
			if (this.state.currentRow > 0) {
				if (this.state.currentRow === this.state.rows - 1) {
					this.setState({
						searchItemActive: this.state.searchItemActive - 5,
						currentRow: this.state.currentRow - 1,
					})
				} else {
					this.setState({
						searchItemActive: this.state.searchItemActive - 5,
						currentRow: this.state.currentRow - 1,
						sliderTimes: this.state.sliderTimes - 1
					})
				}
			}
		}
	}

	enter = () => {
		this.props.setActiveGame(this.state.filterArray[this.state.searchItemActive])
		this.props.setOpenGame(true, "game")
	}

	handleClick = (index) => {
		this.props.setActiveGame(this.state.filterArray[index])
	}

	handleChange = (e) => {

		let searchValue = new RegExp(e.target.value, "gi");
		let newarr = [];

		//iterate to all the categories list then every game in that list, then every tag in that game
		this.props.categories.map(category => {
			category.games.map(game => {
				game.tags.map(tag => {
					if (tag.match(searchValue) && e.target.value !== "") {
						newarr.push(game)
					}
				})
			})
		})

		if(newarr.length === 0 && e.target.value.length !== 0){
			this.setState({
				showError:true,
				position:0
			})
		}else{
			this.setState({
				showError: false
			})
		}

		// clean muiltiple items (because tags list can match more than one time)
		let filterarray = [];
		newarr.map(x => {
			if (!filterarray.includes(x)) {
				filterarray.push(x)
			}
		})


		this.setState({
			inputValue: e.target.value,
			searchItemActive: -1,
			filterArray: [...filterarray]
		}, () => {
			this.setRows();
		})
	}

	handleMouseOver = () => {
		this.setState({
			mouseOver: true
		})
	}

	handleMouseOut = () => {
		this.setState({
			mouseOver: false
		})
	}



	render() {
		return (
			<div className="SearchUI page-container">

				<div className="serachDisplay">

					<div className="searchUser">
						<h1> Search</h1>
						<div className="inputField">
							<input id="search" type="text" 
							value={this.state.inputValue} 
							onChange={(e) => this.handleChange(e)} 
							placeholder="Search for any game" 
							autoFocus 
							autoComplete="off"
							/>
						</div>
					</div>


					<div className="results-container">
					<div className="results" style={{ WebkitTransform: `translateY(${230 * -this.state.sliderTimes}px)` }}>
							{this.state.filterArray.map((game, index) => (
								<div key={index} className={"searchItem" + (this.state.searchItemActive === index && this.state.position === 1 ? " searchActive" : "")} >
									<GameCard
										id={index}
										gameIndex={index}
										gameImage={game.asset.thumbnail}
										gameTitle={game.gameName}
										setActiveGame={this.setActiveGame}
										url={this.props.url}
									/>
								</div>
							))}
					</div>
					</div>
					{this.state.showError &&
								<p id="errorSearch">sorry! I couldn't find the game you're looking for</p>
							}

				</div>
							
			</div>
		)
	}
}

export default SearchUI
