import React, { Component, Fragment } from 'react'
import Utility from '../Utility';

import '../Styles/Menu.css'
import MenuItem from './MenuItem';

var exit = {
	menu_name: "Exit",
	menu_img: "https://ctv.games/Images/exit_icon.png",
	menu_img_hover: "https://ctv.games/Images/exit_icon_white.png",
	page_id: "100",
	page_type_id: "100"
}

class NavMenu extends Component {
	constructor(props) {
		super(props)

		this.state = {
			menuPos: 0,
			menuArray: [...this.props.menu],
		}
	}

	componentDidMount() {

		if(this.props.device == "pc"){
			document.getElementById("menu").style.height ="100vh"
		}else{
			document.getElementById("menu").style.height ="1080px"
		}

		let items = document.getElementsByClassName("menu-item");
		for (let i = 0; i > -items.length; i--) {
			items[-i].addEventListener("click", () => {

				this.props.handleMenuClick(i);
				this.setState({
					menuPos: i
				})
			})
		}
	}


	componentDidUpdate(prevProps) {
		if (this.props.menu !== prevProps.menu) {
			this.setState({
				menuLength: this.props.menu.pages.length,
				menuArray: [...this.props.menu]
			})
		}
		if (this.props.screen !== prevProps.screen) {
			if (this.props.screen == "menu") {
				Utility.addKeyHandler(this.keyHandler);

			}
			else {
				Utility.removeKeyHandler(this.keyHandler);
			}
		}
	}


	componentWillUnmount() {
		Utility.removeKeyHandler(this.keyHandler);

	}


	keyHandler = (key) => {
		switch (key.keyCode) {
			case Utility.input.down:
				//if we clickdown and MENU screen IS ACTIVE go to negetive values ( unique values)
				this.down();
				break;

			case Utility.input.up:
				this.up();
				break;
			case Utility.input.right:
				this.ExitMenu();
				break;
			case Utility.input.enter:
				this.enter();
				break;
			case Utility.input.backLG:
			case Utility.input.backPC:
			case Utility.input.backSam:
				this.ExitMenu();
				break;
		}
	}

	down = () => {
		if (this.state.menuPos < this.state.menuArray.length && this.props.screen === "menu") {
			this.setState({
				menuPos: this.state.menuPos + 1
			})
		} else {
			this.setState({
				menuPos: 0
			})
		}
	}
	up = () => {
		if (this.state.menuPos <= 0 && this.props.screen === "menu") {

			this.setState({
				menuPos: this.state.menuArray.length
			})
		} else {
			this.setState({
				menuPos: this.state.menuPos - 1
			})
		}
	}
	ExitMenu = () => {
		if (this.props.screen === "menu") {
			this.props.setScreen(this.props.prevScreen);
			this.props.setPlayerPosition(1)
		}
	}
	enter = () => {
		if (this.props.screen === "menu" && this.state.menuPos !== this.state.menuArray.length) {
			this.props.handleMenuClick(this.state.menuPos)
		}

		if (this.state.menuPos === this.state.menuArray.length) {
			this.props.handleMenuClick(100)
		}
	}

	render() {
		return (
			<Fragment>

				<div className="menu-top" >
					<div className="navigationMenu" id="menu" style={this.props.screen === "menu" ? { width: "200px" } : { width: "70px" }}>
						<div id="menu-logo" className="menu-item-logo" style={this.props.screen === "menu" ? { padding: "2em 1em 0 1em" } : { padding: ".8em .3em 0 .3em" }}>
							<img src={this.props.appLogo} width="80%" alt="mars games" />
						</div>
						<ul className="menuItemsList">
							{this.state.menuArray.map((item, index) => (
								<Fragment key={index}>
									<MenuItem
										index={index}
										item={item}
										menuPos={this.state.menuPos}
										screen={this.props.screen}
									/>

								</Fragment>

							))
							}
						</ul>
						<ul className="menu-list-bottom">
							<MenuItem
								index={this.state.menuArray.length}
								item={exit}
								menuPos={this.state.menuPos}
								screen={this.props.screen}
							/>
						</ul>
					</div>
					<div className="border"></div>
				</div>


			</Fragment>

		)
	}
}

export default NavMenu
