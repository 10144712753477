import React from 'react';
function SplashScreen(props) {
	return (
		<div className="splashScreen">

			<div className="splashScreenLogo">
				<img src={props.logo} width="400px" />
			</div>
			{props.showEror === true
				? <p id="error-message">oops! something went wrong</p>
				: <div className="splashScreenContent">
					<div className="big" />
					<div className="small" />
				</div>
			}

		</div>
	)
}

export default SplashScreen;
