import './App.css';
import AppManager from './components/AppManager';


import React from 'react';
import InitDevices from './InitiDevices/InitDevices';

function App() {

	const globalValue = {
		device: InitDevices.getDevice()
	}

	const Context = React.createContext("pc")

	return (
		<>
			<Context.Provider value={globalValue}>
				<AppManager />
			</Context.Provider>

		</>
	)
}

export default App;
