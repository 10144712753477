
class initSam{
	getKeys = () => {
		let keys = [
			'MediaPlay',
			'MediaPause',
			'MediaStop',
			'MediaPlayPause',
			'ColorF0Red',
			'ColorF1Green',
			'ColorF2Yellow',
			'ColorF3Blue',
			'ChannelUp',
			'ChannelDown',
			'Exit',
			'0',
			'1',
			'2',
			'3',
			'4',
			'5',
			'6',
			'7',
			'8',
			'9',
			'MediaRewind',
			'MediaFastForward',
			'MediaRecord'
		];
		this.registerKeys(keys);
	}

	registerKeys = keys => {
		for (var key in keys) {
			// tizen.tvinputdevice.registerKey(keys[key]);
		}
		// tizen.tvinputdevice.registerKeyBatch(keys);
	};

	onDocumentHide = () =>{
			// tizen.application.getCurrentApplication().hide();
	}
	
	exitApp = ()=>{
		// tizen.application.getCurrentApplication().exit();
	}
}

export default new initSam();