import initSam from "./InitiDevices/initSam";

class Utility {
	input = {
		//global codes
		left: 37,
		right: 39,
		up: 38,
		down: 40,
		enter: 13,
		exit: 10182,
		backPC: 27,
		backLG: 461,
		backSam: 10009,
	}


	getCarouselEdge = () => {
		const windowWIdth = window.innerWidth;

		if (windowWIdth <= 1850) {
			return 4
		} else {
			return 5

		}
	}

	addKeyHandler = (handler) => {
		window.addEventListener("keydown", handler);
		//  console.log("key pressed")
		// window.addEventListener("keyup", ()=> document.body.style.pointerEvents = 'auto');
	};

	removeKeyHandler = handler => {
		window.removeEventListener("keydown", handler);
		// window.removeEventListener("keyup", () => document.body.style.pointerEvents = 'auto');
	};

	exitApp = (OS) => {
		switch (OS) {
			case "Samsung":
				initSam.exitApp();
				break;
			case "LG":
			case "pc":
				window.close();
				break;
		}
	}


}




export default new Utility();