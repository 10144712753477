import React, { Component } from 'react'
import GameCard from './GameCard'
import Utility from '../Utility';


var arr = [];

class Carousel extends Component {
	constructor(props) {
		super(props)

		this.state = {
			activeIndex: 0,
			activeCategory: {},
			sliderOffSets: 0,
			carosuelTransitionEnd: true,
			startIninite: false,
		}
		this.carouselLength = this.props.category.games.length
		this.outerIndex = 0
	}

	componentDidMount() {
		Utility.addKeyHandler(this.keyHandler);
		// let height = document.getElementById("box").clientHeight;
		if (this.props.carouselIndex > 0)


			document.getElementById(`carouselHolder${this.props.carouselIndex}`).addEventListener("transitionend", () => {
				this.setState({
					transitionend: true
				})
			});
		document.getElementById(`test`).addEventListener("transitionend", () => {
			this.props.setTransitionEnd(true)
		});

		// for (let i = 0; i < this.props.category.games.length; i++) {
		// 	arr[i] = -this.state.elementWidth
		// }

	}

	componentWillUnmount() {
		Utility.removeKeyHandler(this.keyHandler);
	}

	componentDidUpdate(prevProps, prevState) {
		if (this.props.carouselIndex === this.props.activeCarouselIndex
			&&
			this.props.screen === "home"
			&&
			this.props.playerPosition !== 0
		) {

			Utility.addKeyHandler(this.keyHandler);
		} else {
			Utility.removeKeyHandler(this.keyHandler);
		}

		if (this.props.activeCarouselIndex !== prevProps.activeCarouselIndex) {
			if (this.props.carouselIndex === 0)
				this.props.setActiveGame(this.props.activeCategory.games[this.state.activeIndex]);
		}

	}

	setActiveGame = (game) => {
		this.props.setActiveGame(game);
		this.enter();
	}

	openActive = () => {
		this.props.openActive();
	}

	keyHandler = (key) => {

		switch (key.keyCode) {
			case Utility.input.up:
				this.up();
				break;
			case Utility.input.down:
				this.down();
				break;
			case Utility.input.left:
				this.left();
				break;
			case Utility.input.right:
				this.right();
				break;
			case Utility.input.enter:
				this.enter();
				break
			case Utility.input.backLG:
			case Utility.input.backPC:
			case Utility.input.backSam:
				this.props.setScreen("menu");
				break;
		}
	}

	down = () => {
		if (this.props.activeCarouselIndex < this.props.categories.length - 1 && this.props.verticalTransitionEnd === true) {
			this.props.setTransitionEnd(false)
		
			this.props.setVeticalView(this.props.verticalView - 307)

			let newCarouselIndex = this.props.activeCarouselIndex + 1;
			this.props.setActiveCategory(this.props.categories[newCarouselIndex])
			this.props.setActiveCarouselIndex(newCarouselIndex);


		}


	}
	up = () => {
		if (this.props.activeCarouselIndex > 0 && this.props.verticalTransitionEnd === true) {
			this.props.setTransitionEnd(false);
			this.props.setVeticalView(this.props.verticalView + 307)

			let newCarouselIndex = this.props.activeCarouselIndex - 1;
			this.props.setActiveCategory(this.props.categories[newCarouselIndex])
			this.props.setActiveCarouselIndex(newCarouselIndex);


		}

	}

	right = () => {
		if (this.state.carosuelTransitionEnd === true && this.state.activeIndex < this.carouselLength - 1) {
			this.setState({
				carosuelTransitionEnd: false,
				activeIndex: this.state.activeIndex + 1
			}, () => {
				this.props.setActiveGame(this.props.category.games[this.state.activeIndex]);
			});
			this.setState({
				sliderOffSets: this.state.sliderOffSets - 320,
			});

			setTimeout(() => {
				this.setState({
					carosuelTransitionEnd: true,
				});
			}, 150);

		}
	}

	
	left = () => {
		if (this.state.activeIndex > 0 && this.state.carosuelTransitionEnd === true) {

			this.setState({
				carosuelTransitionEnd: false,
				activeIndex: this.state.activeIndex - 1,
				sliderOffSets: this.state.sliderOffSets + 320
			})
			setTimeout(() => {
				this.setState({
					carosuelTransitionEnd: true
				})
			}, 200);
			this.props.setActiveGame(this.props.category.games[this.state.activeIndex]);
		} else {
			//stop listen to this keyhandler and open menu

			if (this.state.activeIndex === 0)
				this.props.setScreen("menu")
		}

	}

	enter = () => {
		this.props.setOpenGame(true, "game")
	}

	modifyText = (str) => {
		let words = str.split(" ");
		for (let i = 0; i < words.length; i++) {
			let word = words[i].slice(0, 1).toUpperCase() + words[i].slice(1, str.length);
			words[i] = word
		}
		return words.join(" ");
	}

	render() {
		return (
			<div className="carousel">

				<div className="carouselData">
					<div className="carouselTitle"> {this.modifyText(this.props.category.categoryName)}</div>
				</div>
				<div className="carousel-list-container">


					<div className="carouselHolder" id={`carouselHolder${this.props.carouselIndex}`} style={{ WebkitTransform: `translateX(${this.state.sliderOffSets}px)` }} >
						{this.props.category.games.map((game, index) =>
							(
								<div className={
									`carouselItem gameCard${this.props.carouselIndex}` +
									(this.state.activeIndex === 0 + index
										? " active"
										: "")
								}
									key={index}

								>
									<GameCard
										carouselIndex={this.props.carouselIndex}
										id={index}
										game={game}
										gameIndex={index}
										gameImage={game.asset.thumbnail}
										gameTitle={game.gameName}
										setActiveGame={this.setActiveGame}
										url={this.props.url}
									/>
								</div>
							)
						)}
					</div>
				</div>

			</div>
		)
	}
}

export default Carousel
