import React, { Component } from 'react'
import Utility from '../Utility';

import '../Styles/popup.css'

class ExitPopUp extends Component {
	constructor(props) {
		super(props)

		this.state = {
			btnPos: 0,
			activePos: 100,
		}
	}

	componentDidMount() {
		Utility.addKeyHandler(this.keyHandler);
	}

	componentWillUnmount() {
		Utility.removeKeyHandler(this.keyHandler);
	}

	keyHandler = (key) => {
		switch (key.keyCode) {
			case Utility.input.left:
		this.setState({
			btnPos: 1,
			activePos: 0
		});
				break;

			case Utility.input.right:
				this.setState({
					btnPos: 0,
					activePos: 100
				});
				break;

			case Utility.input.enter:
				if (this.state.btnPos === 0) {
					this.handleNo();
				}
				if (this.state.btnPos === 1) {
					Utility.exitApp(this.props.device)
				}
				break;
			// need to add state for every window

			case Utility.input.backLG:
				case Utility.input.backPC:
				case Utility.input.backSam:
					this.props.setScreen(this.props.prevScreen);
					break;
		}
	}

	handleNo = () => {
		this.props.closeExit()
	}
	handleYes = () => {
		Utility.exitApp(this.props.device)
	}

	render() {
		return (
			<div className="exitPopUp">
				<div className="backPopup-container">
					<div className="backPopup" />
					<div className="popUp">
						<div className="popupTitle">
							Are you sure you want to exit?
                            </div>

						<div className="popUpButton">
						<div id="activeBox" style={{WebkitTransform: `translateX(${this.state.activePos}%)`}} />
							<div className={"btn" + (this.state.btnPos === 1 ? " BTNactive" : "")} onClick={() => this.handleYes()}>
								Yes
                                </div>
							<div className={"btn" + (this.state.btnPos === 0 ? " BTNactive" : "")} onClick={() => this.handleNo()}>
								No
              </div>
						</div>
					</div>

					<div className="mars-logo">
						<img src={this.props.appLogo} />
					</div>
				</div>
			</div>
		)
	}
}

export default ExitPopUp
