import React, { useEffect } from 'react';
import '../Styles/Menu.css'

function MenuItem(props) {

	return (
		<li className={"menu-item" + (props.menuPos === props.index && props.screen === "menu" ? " active" : "")}>
			{props.menuPos === props.index && props.screen === "menu"
			? <img src={props.item.menu_img_hover} />
			:<img src={props.item.menu_img} />
			}
			
			<span className="span">{props.item.menu_name}</span>
		</li>
	)
}

export default MenuItem;
