import '../Styles/Home.css'
import React from 'react';

function HomeSlider(props) {

	let modifyText = (str) => {
		let words = str.split(" ");
		for (let i = 0; i < words.length; i++) {
			let word = words[i].slice(0, 1).toUpperCase() + words[i].slice(1, str.length);
			words[i] = word;
		}
		return words.join(" ");
	}

	return (
		<div className="detailedView" >
			<div className="activeImage" />

			<div className="activeInfo">
				<div className="activeTitle">
					{modifyText(props.activeGame.gameName)}
				</div>
				<div className="activeDescription">
					{props.activeGame.description}

				</div>
			</div>
		</div>


	)
}

export default HomeSlider;
