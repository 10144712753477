import React, { Component } from 'react'

class GameCard extends Component {
    constructor(props) {
        super(props)

        this.state = {
            mouseOver : false
        }
    }

    handleClick = (id)=>{
        // this.props.setActiveGame(id);
        this.props.setActiveGame(id);
    }

    handleMouseOver = () => {
        this.setState({
            mouseOver: true
        })
    }

    handleMouseOut = ()=>{
        this.setState({
            mouseOver: false
        })
		}	
		
		modifyText = (str) => {
			let words = str.split(" ");
			for (let i = 0; i < words.length; i++) {
				let word = words[i].slice(0, 1).toUpperCase() + words[i].slice(1, str.length);
				words[i] = word;
			}
			return words.join(" ");
		}

    render() {
        return (
            <div className={`gameCard`} onClick={ ()=>  this.props.setActiveGame(this.props.game) } onMouseOver={()=> this.handleMouseOver() } onMouseLeave={()=> this.handleMouseOut()} >
                <div className="image" id={`image${this.props.id}`}  >
                    <div style={ this.state.mouseOver === true ? {top:0} : {top: "100%"}} className="test">{this.modifyText(this.props.gameTitle)}</div>
                    <img src={this.props.url+this.props.gameImage} className="gameImage" />
                </div>

    
            </div>

        )
    }
}

export default GameCard
