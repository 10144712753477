import React, { Component } from 'react'
import Utility from '../Utility'
import ExitGame from './ExitGame';

// code for gams : window.parent.window.postMessage("#1998", "*");	
class GameFrame extends Component {
	constructor(props) {
		super(props)

		this.state = {
			showexit: false
		}
	}
	componentDidMount() {

		document.getElementById("gameFrame").addEventListener("load", this.setFocusOnFrame)


		// let a = document.getElementById("gameFrame");
		// setTimeout(() => {
		// 	a.contentWindow.focus();
		// }, 1000);
		// document.getElementById("frame-container").focus()


		window.addEventListener('message', event => {
			if (event.data !== "#1998") {
				return;
			}
			this.showExitDialog()

		});

		// let content = a.contentWindow.window.body.addEventListener("keydown", ()=> console.log("dsadas"))
	}

	setFocusOnFrame = function(){
		console.log("whata")
		document.getElementById("gameFrame").focus();
	}

	showExitDialog = () => {
		this.setState({
			showexit: true
		});
		document.getElementById("gameFrame").blur()
	}

	componentWillUnmount() {
		this.setState({
			showexit: false
		})
	}

	keyHandler = (key) => {
		switch (key.keyCode) {
			case Utility.input.down:
				console.log("back");
				break;
		}
	}

	setShowExit = () => {
		this.setState({
			showexit: false
		})
			document.getElementById("gameFrame").focus();
		

	}

	backTohome = ()=>{
		this.props.backToHomeFromGame()
	}
	//{`https://ctv.games/${this.props.gameURL}`}
	render() {
		return (
			<div className="frameWindow" id="frame-container" tabIndex="0">
				<iframe id="gameFrame" src={`https://ctv.games/${this.props.gameURL}`} width="100%" height="100%" frameBorder="0" />
					: ""
				{this.state.showexit && <ExitGame setScreen={this.props.setScreen}  backTohome={this.backTohome} setShowExit={this.setShowExit} appLogo={this.props.appLogo} />}
			</div>
		)
	}
}

export default GameFrame
