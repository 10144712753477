import React, { Component } from 'react'
import Carousel from './Carousel';
import NavMenu from './NavMenu';

import SearchUI from './SearchUI'
import HomeSlider from './HomeSlider';
import GameFrame from './GameFrame';
import ExitPopUp from './ExitPopUp';

import SplashScreen from './SplashScreen';

import '../Styles/Home.css'
import InitDevices from '../InitiDevices/InitDevices';

var url = "https://ctv.games/";

var newArray = []

class AppManager extends Component {
	constructor(props) {
		super(props)

		this.state = {
			showSplashScreen: true,
			dataready: false, //data ready
			openActive: false, //show game window
			activeGame: {}, // return the active vidoe object
			activeCarouselIndex: 0, //represent the active carousel
			playerPosition: 1, //where the mouse is
			screen: "home", //handle which screen is rendred
			verticalView: 0, //offscreen value for vertical scrolling
			gameIsOpen: false, //open game iframe
			showExitPopUP: false, //show exit popup
			openSearch: false, // should open search GUI or not
			showMenu: false, // should show menu? fasle only when game is playing
			prevScreen: "home",
			device: "",
			graphic: {},
			categories: [],
			activeCategory: {},
			verticalTransitionEnd: true,
			marginLeft: 0,
			showPage: false,

			menu: [],

			actionCategory: [],
			boardCategory: [],
			brainCategory: [],
			classicCategory: [],
			carsCategory :[]
		}
	}

	componentDidMount() {
		this.getDataApi();
		this.initDevice();


	}

	componentDidUpdate(prevProps, prevState) {
		if (prevState.screen !== this.state.screen) {

			if (prevState.screen !== "menu" && prevState.screen !== "exit") {
				this.setState({
					prevScreen: prevState.screen
				})
			}

			//if menu is open shift everything left
			if (this.state.screen === "menu") {
				this.setState({
					marginLeft: 130,
					playerPosition: -3
				})
			} else {
				this.setState({
					marginLeft: 0,
				})
			}

			if (this.state.screen === "game") {
				this.setState({
					showMenu: false,
					openSearch: false
				})
			}
			// console.log(this.state.screen, "from update")
		}
	}

	initDevice = () => {

		const device = InitDevices.getDevice()

		this.setState({
			device
		})

	}

	// fetch("https://cors-anywhere.herokuapp.com/http://cdn.videofy.ai/thumbnails/ctvjson", {
	// 	mode: "cors", headers: {
	// 		'Content-Type': 'application/json'
	// 	}
	// })


	//get data from api
	getDataApi = () => {
		fetch("https://ctv.games/api/MarsGames.json")
			.then(res => res.json())
			.then(res => {
				this.setState({
					activeGame: res.games[0],
					graphic: res.graphic,
					dataready: true,
					menu: [...res.menu]
				});

				this.newCategorize(res.games)
				//hide splash screen and show menu
				setTimeout(() => {
					this.setState({
						showSplashScreen: false,
						showMenu: true
					})
				}, 2000)

				this.setState({
					activeCategory: this.state.categories[0]
				})
			})
			.catch(err => {
				this.setState({
					showEror: true
				})
				console.log("Error Response Data: " + err)
			})
	}

	newCategorize = (gameList) =>{
		gameList.map(game => this.getGame(game));
		this.setState({
			categories: newArray.slice()
		})
	}

	getGame = (game) =>{
		game.category.map( category => this.checkCarousel(category, game))
	}

	checkCarousel = (category , game) =>{
		let isExist = newArray.some(x => x.categoryName === category);
		if (isExist) {
			let index = newArray.findIndex(x => x.categoryName === category) // lets find the carousel postion in the array
			newArray[index].games = [...newArray[index].games , game];
		} else {
			newArray = this.genreateCarousel(category, game);
		}
	}

	genreateCarousel = (category,obj) => {
		return [...newArray, { categoryName: category, games: [obj] }]
	}

	backToHomeFromGame = () => {
		this.setState({
			playerPosition: 1,
			showMenu: true,
			gameIsOpen: false,
			openSearch: false,
			showExitPopUP: false,
			screen: "home"
		})
	}

	setVerticalTranstionEnd = (state) => {
		this.setState({
			verticalTransitionEnd: state
		})
	}

	setActiveCarousel = activeCarouselIndex => {
		this.setState({
			activeCarouselIndex
		})
	}

	setActiveGame = activeGame => {
		this.setState({
			activeGame
		})
	}

	setActiveCategory = activeCategory => {
		this.setState({
			activeCategory
		})
	}

	setPlayerPosition = playerPosition => {
		this.setState({
			playerPosition
		})
	}

	setScreen = screen => {
		this.setState({
			screen
		})

	}

	setVerticalCarouselBox = (verticalView) => {
		this.setState({
			verticalView
		})
	}

	handleMenuClick = (menuPos) => {
		switch (menuPos) {

			case 0:
				this.setState({
					screen: "home",
					playerPosition: 1,
					openSearch:false
				});
				break;
			case 1:
				//search item selected
				this.setState({
					openSearch: true,
					screen: "search"
				})
				break;

			case 100:
				this.openExitPopUP();
				break;
		}
	}

	openExitPopUP = () => {
		this.setState({ showExitPopUP: true, playerPosition: 6 })
		this.setScreen("exit");
	}

	setGameIsOpen = (state, screen) => {
		this.setState({
			gameIsOpen: state,
			screen: screen
		})
	}

	setTest = (test) => {
		this.setState({
			test
		})
		console.log(this.state.test)
	}

	closeExit = () => {
		this.setState({
			showExitPopUP: false,
			screen: this.state.prevScreen,
			playerPosition : 1
		})
	}

	render() {
		let {
			categories,
			activeGame,
			activeCategory,
			activeCarouselIndex,
			playerPosition,
			screen,
			verticalView,
			prevScreen,
			showSplashScreen,
			device,
			verticalTransitionEnd,
			graphic,
		} = this.state

		return (
			<div className="warpper">
				{showSplashScreen === true
					? <div className="splashScreen">
						{
							<SplashScreen logo={graphic.appLogo} showEror={this.state.showEror} />
						}

					</div>
					: ""
				}

				{this.state.showMenu
					? <NavMenu
						playerPosition={playerPosition}
						setScreen={this.setScreen}
						screen={screen}
						setPlayerPosition={this.setPlayerPosition}
						handleMenuClick={this.handleMenuClick}
						prevScreen={prevScreen}
						appLogo={graphic.appLogo}
						menu={this.state.menu}
						backToHomeFromGame={this.backToHomeFromGame}
						device={this.state.device}
					/>
					: ""
				}

				<div className="content">

					{(this.state.openSearch) &&
						<SearchUI
							categories={categories}
							setActiveGame={this.setActiveGame}
							screen={screen}
							setScreen={this.setScreen}
							setPlayerPosition={this.setPlayerPosition}
							activeGame={this.state.activeGame}
							url={url}
							setOpenGame={this.setGameIsOpen}
						/>
					}

					{this.state.gameIsOpen
						&&
						<GameFrame
							gameURL={this.state.activeGame.gameLink}
							setGameIsOpen={this.setGameIsOpen}
							setScreen={this.setScreen}
							appLogo={graphic.appLogo} 
							backToHomeFromGame ={this.backToHomeFromGame}
							/>
					} 
							<div className="homePage" style={this.state.dataready === true && screen !== "search" ? { backgroundImage: `url(${url + this.state.activeGame.asset.cover})`, marginLeft: `${this.state.marginLeft}px` } : {}}>
								{this.state.dataready && screen !== "search" ?
									<HomeSlider
										activeGame={activeGame}
										games={categories}
										graphic={graphic}
									/>
									: ""
								}
								<div className="CarouselBox" >
									{/* <div className="hide" /> */}
									{this.state.playerPosition === 1 &&
										<div className="activeBox" />
									}


									<div className="carouselList" id="test" style={{ WebkitTransform: `translateY(${this.state.verticalView}px)` }}>
										{categories.map((category, index) =>
											(
												<Carousel
													key={index}
													carouselIndex={index}
													activeCarouselIndex={activeCarouselIndex}
													setActiveCarouselIndex={this.setActiveCarousel}
													category={category}
													categories={categories}

													setTransitionEnd={this.setVerticalTranstionEnd}
													verticalTransitionEnd={verticalTransitionEnd}
													url={url}
													setOpenGame={this.setGameIsOpen}
													activeGame={this.state.activeGame}
													setActiveGame={this.setActiveGame}

													activeCategory={activeCategory}
													setActiveCategory={this.setActiveCategory}

													screen={screen}
													setScreen={this.setScreen}
													setVeticalView={this.setVerticalCarouselBox}
													verticalView={verticalView}
													playerPosition={playerPosition}
													device={device}
												/>
											)
										)}
									</div>
								</div>

							</div>
				</div>
				{this.state.showExitPopUP &&
					<ExitPopUp
						setScreen={this.setScreen}
						device={device}
						appLogo={graphic.appLogo}
						closeExit={this.closeExit}
					/>}

			</div>
		)
	}
}

export default AppManager
